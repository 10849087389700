<template>
  <div class="iriets-product">
    <product-som v-if="id==1"></product-som>
    <product-dict v-if="id==2"></product-dict>
    <product-aish v-if="id==3"></product-aish>
    <product-ranji v-if="id==4"></product-ranji>
    <product-data v-if="id==5"></product-data>
    <product-tags v-if="id==6"></product-tags>
    <product-saas v-if="id==7"></product-saas>
    <product-iot v-if="id==8"></product-iot>
    <product-isky v-if="id==9"></product-isky>
    <product-qyy v-if="id==10"></product-qyy>
    <product-xhypms v-if="id==11"></product-xhypms>
  </div>
</template>

<script>
import productSom from "./page/product-som.vue";
import productDict from "./page/product-dict.vue";
import productAish from "./page/product-aish.vue";
import productRanji from "./page/product-ranji.vue";
import ProductRanji from "./page/product-ranji.vue";
import ProductData from "./page/product-data.vue";
import ProductTags from "./page/product-tags.vue";
import ProductSaas from "./page/product-saas.vue";
import ProductIot from "./page/product-iot.vue";
import ProductIsky from "./page/product-isky.vue";
import ProductQyy from "./page/product-qyy.vue";
import ProductXhypms from "./page/product-xhypms.vue";
export default {
  data() {
    return {
      id: null
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    console.log(this.id);
  },
  methods: {},
  watch: {
    $route() {
      this.id = this.$route.params.id;
      console.log(this.$route.params.id);
    }
  },
  components: {
    productSom,
    productDict,
    productAish,
    productRanji,
    ProductRanji,
    ProductData,
    ProductTags,
    ProductSaas,
    ProductIot,
    ProductIsky,
    ProductQyy,
    ProductXhypms
  }
};
</script>

<style scoped lang="less">
</style>

<template>
  <div class="platform-item" :class="showPadding ? '' : 'show-padding'" :style="{'background':bgColor?bgColor:''}">
    <div class="wrapper">
      <div v-if="alignType=='left'">
        <el-row :gutter="60">
          <el-col :span="11" :style="{'padding-top': isMobile ? '' : paddingTop}">
            <div class="item ">
              <p class="t-title">{{name}}</p>
              <span class="item-hr"></span>
              <p class="t-desc">
                {{desc}}
              </p>
              <p class="t-tags">
                <el-tag v-for="item in tags" :key="item">{{item}}</el-tag>
              </p>
            </div>
          </el-col>
          <el-col :span="13">
            <img :class="showShaow ? 'shaow' : 'no-shaow'" :src="productImg" alt="">
          </el-col>
        </el-row>
      </div>
      <div v-if="alignType=='right'">
        <el-row :gutter="60">
          <el-col :span="13">
            <img :class="showShaow ? 'shaow' : 'no-shaow'" :src="productImg" alt="">
          </el-col>
          <el-col :span="11" :style="{'padding-top': isMobile ? '' : paddingTop}">
            <div class="item ">
              <p class="t-title">{{name}}</p>
              <span class="item-hr"></span>
              <p class="t-desc">
                {{desc}}
              </p>
              <p class="t-tags">
                <el-tag v-for="item in tags" :key="item">{{item}}</el-tag>
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: "#fff"
    },
    name: {
      type: String,
      default: ""
    },
    desc: {
      type: String,
      default: ""
    },
    align: {
      type: String,
      default: "left"
    },
    tags: {
      type: Array,
      default: () => []
    },
    productImg: {},
    showShaow: {
      type: Boolean,
      default: true
    },
    paddingTop: {
      type: String,
      default: "0px"
    },
    showPadding: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isMobile: window.isMobile
    };
  },
  created() {
    if (window.isMobile) {
      this.alignType = "left";
      console.log("left");
    } else {
      this.alignType = JSON.parse(JSON.stringify(this.align));
    }
  },
  methods: {},
  components: {}
};
</script>

<style scoped lang="less">
.shaow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}
</style>

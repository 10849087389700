<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image" :style="{'backgroundImage':`url(${require('@/assets/product/10/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="14">
              <div class="f50 cfff title">趣游园智慧景区SaaS管理平台</div>
              <div class="f20 cfff desc">趣游园是一个面向中小型休闲庄园、景区、公园的基于SaaS模式的智慧景区管理平台，贴合国家乡村振兴政略，以促进乡村旅游发展为使命。</div>
            </el-col>
            <el-col :span="10">
              <img class="r-img qyy-img" src="@/assets/product/10/2.png" alt="">
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/10/3.png" alt="">
          <div class="f16 c333 title">
            通过微信小程序将游客、景区和入驻的商户紧密联系起来，提升游客体验，增加游客粘度，并可通过社交媒体等渠道与游客进行互动，提供个性化的营销推广活动；基于采集的数据，平台还可以帮助管理者进行市场调研和分析，制定精准的市场推广策略，从而吸引更多游客前来参观和体验，提升热度和收益。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/10/4.png" alt="">
              <p class="s-title">AI导览</p>
              <p class="s-desc">小程序内置丰富的景区信息，包括地图、景点介绍、公共设施等。游客可以通过手机随时随地获取这些信息，更好地了解景区情况，制定合理的游园计划。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/10/5.png" alt="">
              <p class="s-title">一码入园</p>
              <p class="s-desc">支持电子票务管理，游客在购票后可直接凭二维码入园，避免了排队等持的麻烦。大大提高了入园效率，使游客能够更快地投入到游园中。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/10/6.png" alt="">
              <p class="s-title">智能引流</p>
              <p class="s-desc">景区利用分享、积分奖励和网红打卡等方式激励游客拍照并分享体验。通过提供优惠券和免费入场券，设立特色背景、雕塑和互动体验等打卡点提升景区的流量和收益。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/10/7.png" alt="">
              <p class="s-title">个性推荐</p>
              <p class="s-desc">可以为游客提供个性化的游园推荐，包括最佳游览路线、热门景点、美食特产等，有助于游客更好地了解景区，并发现更多自己喜欢的景点。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/10/8.png')" :showShaow="false" :showPadding="false" paddingTop="80px" :tags="['一码入园']" :name="'在线预订与支付'" :desc="'趣游园提供在线预订与支付功能，游客可以通过购买门票、联卡、预约景区活动等方式获得入园资格，购买后平台自动生成入园码，游客到达景区出示入园码进行核销之后即可入园，实现了游客“一码入园”自助化入园体验，节省景区人力成本和排队烦恼。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/10/9.png')" :showShaow="false" :showPadding="false" paddingTop="100px" :align="'right'" :tags="['语音讲解','智能推荐玩法']" :name="'景区导览'" :desc="'趣游园提供景区导览功能，包括景点介绍、地图导航、路线规划、商店、餐饮等。通过小程序，用户可以了解到各个景点的详细信息，并根据自身的兴趣和需求进行自由规划旅游路线，方便快捷地探索景区。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/10/10.png')" :showShaow="false" :showPadding="false" :name="'活动预约'" paddingTop="100px" :tags="['资源共享','探索自然','演出娱乐']" :desc="'趣游园提供景区活动资讯功能，包括活动通知、日程安排、费用介绍等。用户可以在小程序中实时获取景区内的各种活动信息，并根据自己的兴趣参与其中，活动的精彩瞬间也会云存储实现资源共享，丰富游览体验和互动。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/10/11.png')" :showShaow="false" :showPadding="false" paddingTop="100px" :align="'right'" :tags="['积分奖励','网红打卡']" :name="'景区引流'" :desc="'景区通过分享转发激励、积分奖励系统和网红打卡点等方式进行引流，鼓励游客在景区内拍照并分享自己的旅行体验，提供优惠券、免费入场券或者积分奖励等激励措施。景区可以设立独特的拍照背景、有特色的雕塑、创新的互动体验等网红打卡点，吸引游客前来打卡并分享，提升景区的活力和收益。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/10/12.png')" :showShaow="false" :showPadding="false" paddingTop="100px" :name="'景区管理'" :tags="['景区管理','物业管理','营销管理']" :desc="'趣游园通过商家管理，营销管理以及物业管理等功能为景区管理者及入驻商家的日常开销和盈利情况提供了数据支持，帮助他们降低人工投入，提高管理效率。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/10/13.png')" :align="'right'" :showShaow="false" :showPadding="false" paddingTop="100px" :tags="['营销统计','商品发布','商品管理']" :name="'商家管理'" :desc="'趣游园提供卡券发放核销、商品发布销售、订单管理、营销数据统计、信息发布等功能，为入驻商家了解游客偏好，消费情况提供数据支持，帮助商家及时调整经营策略，提升竞争力和盈利能力。'">
      </platform-item>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
.shaow {
  box-shadow: none;
}
</style>

<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image aish" :style="{'backgroundImage':`url(${require('@/assets/product/3/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">智能审核平台</div>
              <div class="f20 cfff desc">
                智能审核平台以文本、图片、视频、音频识别技术为核心，为各业务团队提供全方位的审核服务，实现“人工+智能”的审核目标，保障系统的内容安全。也致力于搭建健康的网络平台，为绿色网络保驾护航。</div>
            </el-col>
            <el-col :span="12">
              <img class="r-img" src="@/assets/product/3/2.png" alt="">
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/1/1.png" alt=""> -->
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex" style="align-items: center;">
          <img src="@/assets/product/3/3.png" alt="">
          <div class="f16 c333 title">
            随着互联网的发展，需要审核的内容量不断增长，内容质量也参差不齐。为了解决这些问题，我们利用人工智能及专业审核经验，打造人机协同、高效能的内容审核管理平台，实现统一管控、实时审查。有效地减轻用户审核工作量，保障网络安全。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :offset="3" :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/3/4.png" alt="">
              <p class="s-title">准确识别</p>
              <p class="s-desc">实现硬件（主机、网络设备、摄像头等）软件（业务平台、网站、数据库等）7*24小时全方位监控，对平台的各种指标及健康度进行实时分析与预警。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/3/5.png" alt="">
              <p class="s-title">人机协同</p>
              <p class="s-desc">将零散的运维工作集中化，通过人工智能技术，对海量运维数据进行智能分析，快速定位关键问题，有效规避无用的告警信息，提升运维排障效率。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/3/6.png" alt="">
              <p class="s-title">场景丰富</p>
              <p class="s-desc">通过丰富的运维脚本库和灵活的任务编排能力，将日常复杂的运维操作标准化、自动化，减少重复劳动，降低人工操作带来的风险，提升企业运维效能。</p>

            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/3/7.png')" :tags="['流程自定义','全程跟踪']" :name="'任务审核'" :desc="'借助高精度的机器审核结果，对审核任务进行深度核查，有效提高审核效率和正确率。平台支持多人多步审核、任务超时提醒、任务误判重审，同类任务批量审核等多种功能，应对不同的审核场景。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/3/8.png')" :align="'right'" :tags="['分类组织管理','自由配置']" :name="'策略配置'" :desc="'自定义多轮审核流程，可针对不同业务，配置不同的审核策略，并可以为各环节指定审核人员，提高审核效率。为业务设置任务计时与提醒，及时处理问题，从而形成安全保障体系，避免因安全尺度问题导致的产品运营风险。通过个性化策略，精准识别产品内容中的不良信息，为产品中的信息内容提供安全性和规范性审核保障。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/3/9.png')" :tags="['秒级通知','多维度告警分析']" :name="'多维统计'" :desc="'平台提供多样性数据统计分析，覆盖涉政、涉黄、涉恐、低质灌水、官方违禁等丰富的审核维度，精准匹配各种审核需求。以可视化方式展示各个业务的统计分析数据及审核结果。'">
      </platform-item>
    </div>
    <div class="sh">
      <div class="wrapper">
        <el-tabs v-model="activeName">
          <el-tab-pane label="图片审核" name="first">
            <div class="plr40 content">
              <p>为用户提供图片内容安全智能审核服务，可帮助用户有效识别涉黄、涉政、血腥暴力、广告传播等违禁内容，规避运营风险。</p>
              <img src="@/assets/product/3/12.png" alt="">
            </div>
          </el-tab-pane>
          <el-tab-pane label="视频审核" name="second">
            <div class="plr40 content">
              <p>为用户提供视频内容安全智能审核服务，可帮助用户有效识别涉黄、涉政、血腥暴力、广告传播等违禁内容，规避运营风险。</p>
              <img src="@/assets/product/3/10.png" alt="">
            </div>
          </el-tab-pane>
          <el-tab-pane label="音频审核" name="third">
            <div class="plr40 content">
              <p>为用户提供音频内容安全智能审核服务，可帮助用户有效识别涉黄、涉政、血腥暴力、广告传播等违禁内容，规避运营风险。</p>
              <img src="@/assets/product/3/13.png" alt="">
            </div>
          </el-tab-pane>
          <el-tab-pane label="文字审核" name="fourth">
            <div class="plr40 content">
              <p>为用户提供文字内容安全智能审核服务，可帮助用户有效识别涉黄、涉政、血腥暴力、广告传播等违禁内容，规避运营风险。</p>
              <img src="@/assets/product/3/14.png" alt="">
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div style="background-color: #fff;overflow: hidden;padding-bottom: 70px;">
      <p class="title">
        功能构架
      </p>
      <div class="wrapper">
        <img class="width-pic" src="@/assets/product/3/11.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {
      activeName: "first"
    };
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>

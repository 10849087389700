<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">SOM智能运维平台</div>
              <div class="f20 cfff desc">提供全面的网络设备性能监控，是具备监控、管理、分析和预测预警功能的新一代运维解决方案，实时保障业务安全稳定的运行。</div>
            </el-col>
            <el-col :span="12">
              <img class="r-img" src="@/assets/product/1/2.png" alt="">
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/1/1.png" alt=""> -->
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/1/3.png" alt="">
          <div class="f16 c333 title">
            SOM智能运维平台是面向运营商客户的全方位智能化运维解决方案，全正贴合核心网，按入网各领域网络设备的运
            维，监控及运营服务需求，平台出多系统组成，采用分布式集构将各系统统一纳管。将各系统数据之间保起一座信
            息所梁，解决平台各系统之前数据证号问题。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/1/4.png" alt="">
              <p class="s-title">科学预测</p>
              <p class="s-desc">基于数据挖掘，自动化建模实现网络设备的趋势预测，为业务的正常运行提供可靠保障。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/1/5.png" alt="">
              <p class="s-title">秒级通知</p>
              <p class="s-desc">针对趋势预测发现的问题事件秒级通知，精准分派相关人员，告别告警风暴，将损失降到最低。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/1/6.png" alt="">
              <p class="s-title">精准定位</p>
              <p class="s-desc">基于大数据挖掘的定障定位体系，实现秒级故障定位, 高效、精准定位故障设备和故障原因。</p>

            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/1/7.png" alt="">
              <p class="s-title">快速恢复</p>
              <p class="s-desc">及时启动应急恢复流程，寻找信令冲击引爆点，快速应用SOM平台实现精准的业务恢复。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/1/8.png')" :tags="['可视化大屏','全方位监控','健康度评估','预警预测']" :name="'网络健康度智慧监控系统'" :desc="'以图形化的方式多角度全方位实时监控各质量因子和业务的健康状态，根据每个质量因子的处理性能信息，获得每个质量因子的健康度以及质量因子所占总体的权重系数。对业务系统的运行状况进行整体上的评估，从而协助应用系统故障快速定位和解决。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/1/9.png')" :align="'right'" :tags="['事件分析','故障定位','预警预测']" :name="'时序关联监控分析'" :desc="'根据监测的各项质量因子数据进行线性模型分析、指标趋势预测，发现问题质量因子并对其进行事件分析，实现秒级故障问题定位，分级故障原因排查，并在时间段中标注故障产生的时间，以及颜色标记故障的严重程度。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/1/10.png')" :tags="['容灾平台','一键倒换','应急恢复']" :name="'网络容灾平台'" :desc="'切实保障业务安全稳健的运行。当设备发生故障时，通过网络容灾平台即可远程登录设备并对其下发指令操作，远程一键执行设备应急倒换，及时启动应急恢复流程，实现故障节点秒级倒换及恢复。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/1/11.png')" :align="'right'" :tags="['备份校验','备份管理']" :name="'自动备份管理'" :desc="'是一套标准化、自动化的备份管理解决方案，实现对各种厂家和类型的设备进行用户数据和系统配置数据的自动备份和管理，并集成了备份文件完整性验证的技术，能够最大程度上保证用户数据和配置文件的安全。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/1/12.png')" :align="'left'" :tags="['实时监控','性能指标裂化','公式定制']" :name="'指标监控'" :desc="'准实时的性能指标监控，通过对统计文件分析实现对各类型设备的实时指标监控。支持设备性能指标裂化，能够根据定制的阈值分析出告警并及时告警通知，最后生成KPI的数据展示和查询列表，同时支持指标数据横纵向比对。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/1/13.png')" :align="'right'" :tags="['值守巡检','运维智能化']" :name="'自动巡检'" :desc="'自动值守系统以智能化的设备巡检为基础，融合仿真拨测验证，改变传统值守工作以专业工程师为主体的工作模式，减轻升级改造中维护任务的复杂度和工作量，实现运维智能化，极大节约日常及工程调整中的人力成本。'">
      </platform-item>
    </div>
    <p class="title">
      功能构架
    </p>
    <div class="wrapper">
      <img class="width-pic mb50" src="@/assets/product/1/14.png" alt="">
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>

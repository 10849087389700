<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image" :style="{'backgroundImage':`url(${require('@/assets/product/9/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">易苍穹无人机调度平台</div>
              <div class="f20 cfff desc">易苍穹无人机调度平台与DJI开放平台深入对接，实现无人机自动导航、自动飞行控制等高级功能，可为应急、消防、安防等场景提供现场监测数据实时采集、现场画面实时回传、无人机自动巡检专业解决方案。</div>
            </el-col>
            <el-col :span="12">
              <img class="r-img" src="@/assets/product/9/2.png" alt="">
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/9/1.png" alt=""> -->
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/9/3.png" alt="">
          <div class="f16 c333 title">
            易苍穹无人机调度平台配合大疆无人机飞行控制系统、适航环测系统、专业的数据传输、图像传输系统以及云台控制系统等手段，实现无人机超远程控制，自由调度，计划任务，适航管控，设备检测，维护预警等能力，为任务执行提供了安全和快捷的保障。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/product/9/4.png" alt="">
              <p class="s-title">统一管理</p>
              <p class="s-desc">统一管理航线规划、作业成果和设备，将所有绑定设备进行分类并管理。监控各设备状态，充分掌握设备情况，以保证后续巡检工作开展。</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/product/9/5.png" alt="">
              <p class="s-title">实时回传</p>
              <p class="s-desc">支持多路无人机低延时高清画面和监测数据实时回传，实时了解一线动态，为现场指挥和应急决策提供有力证据。</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/product/9/6.png" alt="">
              <p class="s-title">智能巡检</p>
              <p class="s-desc">航线规划功能，进行精确的巡检路线规划。无人机结乘任务时将巡检数据自动上传至云数据库并建档无需人为操作，实现全流程无人化。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/9/7.png')" :tags="['全方位监控','实时跟踪']" :name="'统一管理'" :desc="'平台对无人机基本信息(支持多台无人机批量导入和配置)、站点信息、负载设备进行统一配置和管理，同时可查看无人机实时状态，第一视角查看实时画面、监测数据以及GIS地图飞行轨迹，亦可以根据现场情况远程控制飞机继续执行或终止执行飞行任务。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/9/8.png')" :tags="['全程记录','预警预测']" :align="'right'" :name="'实时直播'" :desc="'支持多路无人机低延时高清画面直播，实时了解一线动态，即时掌控全局。通过在GIS地图上选点规划飞行路线，配置航线上无人机飞行速度、高度偏航角、云台角度。平台航点参数配置可以分别设置每个航点的速度、高度等参数，绑定无人机在航点上做出悬停、转向、拍照、录像等动作。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/9/9.png')" :tags="['轨迹记录']" :name="'作业管理'" :desc="'平台提供快速任务管理功能，仅需在地图上选点创建的快速飞行任务，航线航点参数均根据往期设定智能填入。平台后台根据配置的时间计划自动调飞无人机完成飞行任务，记录飞行轨迹和飞行视频，并反馈飞行结果。'">
      </platform-item>
    </div>
    <div class="business-process-diagram">
      <p class="title">
        业务流程图
      </p>
      <div class="wrapper">
        <img class="width-pic mb50" src="@/assets/product/9/10.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
.business-process-diagram {
  background: #f3f5fc;
  overflow: hidden;
}
</style>

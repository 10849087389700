<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image" :style="{'backgroundImage':`url(${require('@/assets/product/11/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">智慧物管系统</div>
              <div class="f20 cfff desc">智慧物管系统利用物联网、云计算、移动互联网、智能终端等新一代信息技术，将智能物管、物业缴费、报事报修、安防监控等诸多服务整合为统一系统，以微信公众号、小程序、数字化安防设施等为基础，构建的智能化物业管理综合服务平台。</div>
            </el-col>
            <el-col :span="12">
              <img class="r-img xhypms-img" src="@/assets/product/11/2.png" alt="" style="">
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/11/1.png" alt=""> -->
    </div>
    <p class="title">系统概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/11/3.png" alt="">
          <div class="f16 c333 title">
            智慧物管系统以IRIETS流程引擎(自研)为核心，结合物管行业客户多年的管理经验根据物管场景下日常管理工作，优选卫生保洁、设备维护、设备维修、灾防管理、秩序维护、教育培训和智慧工牌等功能打造符合物管核心业务要求的功能体系，在业务上能够建立基础的管理规范，使得管理上有序可控、快速提升工作与管理效率，带来长远的投资回报。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">系统优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/product/11/4.png" alt="">
              <p class="s-title">智能管理</p>
              <p class="s-desc">通过自动化、智能化的管理方式，根据人员状态智能派单，提高工作效率。同时，智能化的监控系统能够及时发现设备故障和安全隐患，减少维修时间和成本。</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/product/11/5.png" alt="">
              <p class="s-title">优质服务</p>
              <p class="s-desc">通过微信公众号和小程序等平台，为业主提供了便捷的物业服务，包括报修投诉、访客管理等，方便业主随时随地参与物业管理和服务。</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="always">
              <img src="@/assets/product/11/6.png" alt="">
              <p class="s-title">精准分析</p>
              <p class="s-desc">智慧物业管理系统可以整合线上线下资源，通过数据分析精准地了解业主和住户的需求，从而提供更精准的增值服务。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">系统功能</p>
      <platform-item :productImg="require('@/assets/product/11/7.png')" :name="'工单调度'" :desc="'系统工单调度包含工单管理、人员管理、进度追踪和数据分析等功能。系统可对工单进行统一管理和调度，设定不同类型的工作人员和团队，允许多个工作人员协同合作，对工单数据进行分析和挖掘，提供有价值的信息和建议，从而提高服务质量和工作效率，改善业主感官。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/11/8.png')" :align="'right'" :name="'监控中心'" :desc="'实时监控并呈现业务现场人员、车辆出勒情况、分布情况、工作轨迹等情况。当检测到异常情况发生时，监控中心能立即发出警报，并对异常情况提供相关轨迹数据，为物业管理人员提供全面、高效、准确的物业管理依据。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/11/9.png')" :name="'电子围栏'" :desc="'通过电子围栏系统和人员定位技术的结合，实现对今日当值的服务人员进行位置实时监控管理，物业管理人员可以更好地掌握员工的位置和活动情况，提高管理效率和服务质量。当出现异常越界情况时，电子围栏系统可以立即触发报警，并将报警信息发送到物业管理中心或指定的监控设备上，以便管理人员迅速采取措施。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/11/10.png')" :align="'right'" :name="'教育培训'" :desc="'支持各种培训课程发布、在线学习及培训情况统计，包括多种素材格式混编的课程，如入职培训、职业技能培训、安全教育等。物业管理人员通过PC端发布培训课程，参加培训的人员可通过我的培训页面查看并完成待完成的课程。'">
      </platform-item>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>

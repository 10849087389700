<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image" :style="{'backgroundImage':`url(${require('@/assets/product/6/1.png')})`}">
        <div class="wrapper">
          <el-row :gutter="40">
            <el-col :span="10">
              <div class="f50 cfff title">标签信息管理系统</div>
              <div class="f20 cfff desc">标签信息管理系统的目的在于从传统的纸质化向电子化转变，提供一种信息快速分享方式。可用于展示商品详情、使用说明书、多媒体图书等各类业务。</div>
            </el-col>
            <el-col :span="14">
              <img class="r-img tags" src="@/assets/product/6/2.png" alt="">
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/6/3.png" alt="">
          <div class="f16 c333 title">
            随着网络时代的日益进步，加速了信息的更迭速度，信息的传递已经从传统的纸质化向电子化转变。传统纸质文件由于容易丢失且内容无法及时得到更新，以及环境保护的重要性，注定会被便捷的无纸化代替。针对以上问题我们构建了一套标签信息管理系统，能够灵活组合文件、图片、音视频等内容生成二维码，实现各类应用和需求便捷分享。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :offset="3" :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/6/4.png" alt="">
              <p class="s-title">信息快速分享</p>
              <p class="s-desc">帮助用户在不同行业、不同场景下，通过二维码减少信息沟通成本，提升营销和管理效率。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/6/5.png" alt="">
              <p class="s-title">内容在线编辑</p>
              <p class="s-desc">可自由编辑排版内容、添加配色、样式等，让内容展示更美观，生成后内容可随时更改。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/6/6.png" alt="">
              <p class="s-title">节约环保</p>
              <p class="s-desc">生成多种内容的二维码记录单，用户扫描二维码，就可以查看详细信息，真正做到将纸质单电子化。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/6/7.png')" :name="'内容编辑'" :desc="'在线制作图片、文件、音视频、PDF、文件等多种内容的二维码。支持自由排版，添加配色样式等操作让内容展示更美观。生成的标签二维码可进行预览、下载操作。生成后可随时更改。可用于展示商品详情、使用说明书、多媒体图书等各类业务。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/6/8.png')" :align="'right'" :name="'内容审核'" :desc="'借助高精度的机器审核结果，对审核任务进行深度核查，精准识别产品内容中的涉政、涉黄、涉恐、官方违禁等不良信息，为用户提供网络健康环境，保障产品中的信息内容安全性和规范性。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/6/9.png')" :name="'信息统计'" :desc="'标签管理系统集体系化的标签建设、运营、管理于一体，实时掌握标签动态，兼具灵活的标签配置与实时标签能力。用户可更快、更灵活的参与标签的创建、应用与管理，赋能业务的同时提升标签的生成效率。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/6/10.png')" :align="'right'" :name="'标签管理'" :desc="'实时统计扫描人数和扫描次数，支持内容批量管理、一键分享、快速预览、下载等服务，提升信息传递效率。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/6/11.png')" :name="'素材管理'" :desc="'用户可上传图片素材、音频素材、视频素材、PDF素材和文件素材五种类型，以减轻标签编辑过程中复杂度，节约时间成本。'">
      </platform-item>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>

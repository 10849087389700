<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image" :style="{'backgroundImage':`url(${require('@/assets/product/8/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">智能物联网平台</div>
              <div class="f20 cfff desc">易锐思智能物联网平台拥有强大的设备接入能力，可有效应对多种复杂场景下设备管理问题，同时支持双副本容灾，拥有秒级容灾切换能力。</div>
            </el-col>
            <el-col :span="12">
              <img class="r-img iot-img" src="@/assets/product/8/2.png" alt="">
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/8/1.png" alt=""> -->
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/8/3.png" alt="">
          <div class="f16 c333 title">
            平台支持设备多网络、多协议、多Agent接入方式，可有效解决设备接入复杂多样性和碎片化难题。平台同时提供丰富的API接口，为第三方业务开发者提供方使快捷的接入体验。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/8/4.png" alt="">
              <p class="s-title">性能卓越</p>
              <p class="s-desc">支持MQTT，CoAP、TCP、HTTP等多种协议接入，支持对接设备类型300+，接入设备总量已突破500万，重点业务设备延迟月均不超过1秒。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/8/5.png" alt="">
              <p class="s-title">业务稳定</p>
              <p class="s-desc">高可用实例支持双副本容灾，拥有秒级容灾切换能力，同时提供7*24小时的安全威胁检测能力，有效保障业务安全稳定。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/8/6.png" alt="">
              <p class="s-title">能力开放</p>
              <p class="s-desc">平台提供丰富的API和完善的文档，如设备管理、数据收集、数据分析、远程控制等接口，为第三方业务开发者提供方便快捷的接入体验。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/8/7.png" alt="">
              <p class="s-title">灵活扩展</p>
              <p class="s-desc">基于云原生架构没计，支持公共云、专有云、混合云多种部署方式，平台资源亦可以随业务需要弹性伸缩、灵活扩展，更好的满足不断增长的业务需求。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/8/8.png')" :name="'设备控制和数据传输'" :desc="'支持MQTT、HTTP、CoAP等多种数据传输协议，以满足不同设备的需求；提供远程控制设备的入口，通过网络远程控制连接的物联网设备，例如远程开关、远程配置、远程升级、功率调整等；平台还支持实时数据传输，允许设备实时发送数据到平台，并开放相关能力以供三方业务平台或设备运维工程师读取数据。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/8/9.png')" :align="'right'" :name="'数据分析和快速响应'" :desc="'平台收集设备上报的属性、事件数据，并提供强大的数据分析能力，可根据产品、设备、事件等多个维度将大量的原始数据聚合与汇总，生成数据摘要、数据趋势等。平台提供实时分析功能，可以通过设置条件表达式等方式设置异常边界，在数据产生时及时分析相关数据，并发出预警和通知。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/8/10.png')" :name="'自动化和场景联动'" :desc="'平台可以根据特定条件或时间触发自动化操作，以实现设备之间的协同工作。例如: 平台传感器设备捕捉到温度低于设定的阈值时，向温度控制器下发温度调节指令。平台结合物联网数据与自动化功能实现场景联动，使各种应用领域中的设备能够更智能、更高效地协同工作，提供了更好的用户体验、更高的生产效率以及更节省的资源利用方案。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/8/11.png')" :align="'right'" :name="'开放能力和业务支撑'" :desc="'平台提供一系列API，并提供成熟的权限管理方案，平台允许开发者、合作伙伴和第三方应用程序访问平台的各种功能和服务，包括设备管理、数据收集、数据分析、事件管理等，使平台能够满足多样化的业务需求，支撑各行业和应用物联网场景方案落地。'">
      </platform-item>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>

<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image" :style="{'backgroundImage':`url(${require('@/assets/product/2/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">DICT集中运维平台</div>
              <div class="f20 cfff desc">
                DICT是一套性能稳定、功能强大的智能运维平台，集成了设备监控、自动化运维、日志分析、业务告警等多种功能，推动了运维工作从无序到有序、从被动到主动、从人工到智能的演进。</div>
            </el-col>
            <el-col :span="12">
              <img class="r-img" src="@/assets/product/2/13.png" alt="">
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/1/1.png" alt=""> -->
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/2/2.png" alt="">
          <div class="f16 c333 title">
            在“互联网+”时代下，运用互联网思维管理运维工作，实现降本增效一直是企业关注的重心。平台利用智能技术对网络、设备、应用等资源进行统一监控和管理，帮助用户提高运维效率。还可通过自定义流程，实现对业务部署、发布、变更、故障处理等作业的自动操作，提升运维能力。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/2/3.png" alt="">
              <p class="s-title">全方位监控</p>
              <p class="s-desc">实现硬件（主机、网络设备、摄像头等）软件（业务平台、网站、数据库等）7*24小时全方位监控，对平台的各种指标及健康度进行实时分析与预警。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/2/4.png" alt="">
              <p class="s-title">智能化运维</p>
              <p class="s-desc">将零散的运维工作集中化，通过人工智能技术，对海量运维数据进行智能分析，快速定位关键问题，有效规避无用的告警信息，提升运维排障效率。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/2/5.png" alt="">
              <p class="s-title">自动化作业</p>
              <p class="s-desc">通过丰富的运维脚本库和灵活的任务编排能力，将日常复杂的运维操作标准化、自动化，减少重复劳动，降低人工操作带来的风险，提升企业运维效能。</p>

            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/2/6.png" alt="">
              <p class="s-title">标准化流程</p>
              <p class="s-desc">符合ITIL标准，包含事件、问题、变更、配置、发布等多种流程管理，支撑系统有效地管控运维流程体系，也让变更风险控制、重大问题处理决策等有了保障。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/2/7.png')" :tags="['7*24h实时监控','智能告警','服务监控']" :name="'监控中心'" :desc="'对运维数据进行统一归纳和梳理，以数据大屏的形式展示系统健康度、各设备的状态及告警信息等。平台监控的类型主要包括硬件：主机、网络设备、摄像头；软件：业务平台、网站、数据库等。深入理解运维场景，提供灵活多样的的告警规则配置功能。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/2/8.png')" :align="'right'" :tags="['秒级通知','多维度告警分析']" :name="'日志告警'" :desc="'基于大数据技术和智能算法，实现对日志的统一采集、处理、存储和分析。通过日志监控告警规则配置、告警触发、告警事件分派、告警处理等完整的流程来实现日志数据的分析与告警。支持复杂多样的数据采集，具备缓存保护机制。可将异常事件告警通过短信、邮件等方式发送给相关人员，实时掌握业务运行状态。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/2/9.png')" :tags="['分类组织管理','自由配置']" :name="'CMDB'" :desc="'全面管理企业运维的元数据并确保数据的准确性和唯一性，支持模型管理、配置项管理、拓扑管理等功能。以可配置、可维护的数据支撑力量促进运维监控、服务管理及数据分析等相关场景落地。通过手动或自动方式，将分散的资源信息进行标准化处理和整合，准确地维护资源的关联关系，并记录资源信息的变化过程。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/2/10.png')" :align="'right'" :tags="['流程自定义','全程跟踪']" :name="'运维流程'" :desc="'平台包含多种流程管理模块，如事件、问题、变更、配置、发布等，能对运维业务的过程进行有效管理。通过简洁、高效的流程，将人员、技术和资源要素连接起来，提高管理水平并确保服务质量。流程处理过程全透明，运动轨迹全程记录，为企业的KPI量化考核提供可靠依据，有效提高客户粘度。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/2/11.png')" :align="'left'" :tags="['丰富的运维脚本库','灵活的编排能力']" :name="'自动化管理'" :desc="'提供灵活易用的脚本编排能力，支撑复杂的运维场景，使得运维工作流程化、简单化。通过制定巡检任务，解决人工巡检中人力耗费高及巡检正确性低的问题。内置丰富的运维操作脚本，开箱即用。形成场景自动处理能力，打造一套完整的自动化运维体系。'">
      </platform-item>
    </div>
    <div style="background-color: #f2f6fc;overflow: hidden;padding-bottom: 70px;">
      <p class="title">
        功能构架
      </p>
      <div class="wrapper">
        <img class="width-pic" src="@/assets/product/2/12.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>

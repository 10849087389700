<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image saas" :style="{'backgroundImage':`url(${require('@/assets/product/7/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">SaaS电商平台</div>
              <div class="f20 cfff desc">
                帮助品牌快速搭建自有渠道电商平台，提供多样的促销活动，丰富可配的活动模版，多终端（小程序、PC、H5等）的展现方式，为品牌快速开展自有电商运营业务赋能。</div>
            </el-col>
            <el-col :span="12">
              <!-- <img class="r-img" src="@/assets/product/3/2.png" alt=""> -->
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/1/1.png" alt=""> -->
    </div>
    <div class="technology-introduce saas">
      <div class="wrapper">
        <p class="title">主要功能</p>
        <el-row :gutter="100">
          <el-col :span="8" class="text-center">
            <img src="@/assets/product/7/2.png" alt="">
            <span class="s-title">组织人员</span>
            <span class="s-desc text-left">专业的技术人员，全球化的运维管理经验，为客户 打造可靠信赖的运维团队。</span>
          </el-col>
          <el-col :span="8" class="text-center">
            <img src="@/assets/product/7/3.png" alt="">
            <span class="s-title">运维流程</span>
            <span class="s-desc text-left">引入ITIL标准，规范运维流程 ，需求快速相应， 提升运维效率。</span>
          </el-col>
          <el-col :span="8" class="text-center">
            <img src="@/assets/product/7/4.png" alt="">
            <span class="s-title">运维平台</span>
            <span class="s-desc text-left">统一运维平台带来一体化全方位的可视、智能 运维新体验。</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="sh saas">
      <p class="title">服务内容</p>
      <div class="wrapper">
        <el-tabs type="border-card" v-model="activeName">
          <el-tab-pane label="商品管理" name="first">
            <div class="plr40 content">
              <el-row :gutter="20">
                <el-col :span="14">
                  <p class="s-title">商家通过后台对商品信息维护，完成商品的编辑、上架、下架、库存管理等功能。</p>
                  <p class="s-title">实施内容</p>
                  <div class="s-desc f16 c666">
                    <p>基础设置，设置品类和品类关联的属性。</p>
                    <p>设置商品基本信息，编辑或导入商品基本信息，如名称，参数，详情图等。</p>
                    <p>设置SKU信息，添加sku配图。</p>
                    <p>对SKU设置对应的价格，库存等信息。 </p>
                    <p>对商品进行上下架等操作。</p>
                  </div>
                </el-col>
                <el-col :span="10">
                  <img src="@/assets/product/7/5.png" alt="">
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="订单履约" name="second">
            <div class="plr40 content">
              <el-row :gutter="20">
                <el-col :span="14">
                  <p class="s-title">从订单交易产生到用户最终收到商品，包括售后的整个过程</p>
                  <p class="s-title">实施内容</p>
                  <div class="s-desc f16 c666">
                    <p>客户下单后如果没有付款后台按照设置的时间通知用户或关闭订单</p>
                    <p>客户付款后按照后台设置，订单同步给OMS/WMS等系统</p>
                    <p>运营人员在后台进行发货，或通过OMS/WMS发货后自动同步至商城，对订单状态和物流</p>
                    <p>信息等进行自动更新 </p>
                    <p>客户收到货并评价，或根据后台设置的固定时间自动完成该订单</p>
                    <p>客户发起退换货需运营人员在后台审核处理</p>
                    <p>运营人员处理售后退换货等流程</p>
                  </div>
                </el-col>
                <el-col :span="10">
                  <img src="@/assets/product/7/6.png" alt="">
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="发布促销活动" name="third">
            <div class="plr40 content">
              <el-row :gutter="20">
                <el-col :span="14">
                  <p class="s-title">通过各种营销活动，可帮助品牌做好精细化、差异化运营，激发潜在客户、增加销售额、提升用户增长、实现品牌宣传。</p>
                  <p class="s-title">实施内容</p>
                  <div class="s-desc f16 c666">
                    <p>活动运营创建营销活动</p>
                    <p>对营销活动发布进行审核</p>
                    <p>对营销活动配置对应的促销规则，如促销方式，促销范围，促销力度等</p>
                    <p>客户可根据营销规则在支付时获得相应的促销优惠 </p>
                    <p>根据设置的促销结束时间，到期后，活动失效</p>
                  </div>
                </el-col>
                <el-col :span="10">
                  <img src="@/assets/product/7/7.png" alt="">
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="technology-introduce saas">
      <div class="wrapper">
        <p class="title">我们的优势</p>
        <el-row :gutter="60">
          <el-col :span="6" class="text-center">
            <img src="@/assets/product/7/8.png" alt="">
            <span class="s-title">多品牌多店铺</span>
            <span class="s-desc c666">支持一个集团下多个品牌，以及每个品牌下多个店铺的模式。</span>
          </el-col>
          <el-col :span="6" class="text-center">
            <img src="@/assets/product/7/9.png" alt="">
            <span class="s-title">多模式支持</span>
            <span class="s-desc c666">支持常规支付（微信、支付宝、银行卡等）以及积分支付。</span>
          </el-col>
          <el-col :span="6" class="text-center">
            <img src="@/assets/product/7/10.png" alt="">
            <span class="s-title">灵活配置扩展性强</span>
            <span class="s-desc c666">支持多种设置如订单编号前缀、商城识别码、购物车相关、会员专享价格等。</span>
          </el-col>
          <el-col :span="6" class="text-center">
            <img src="@/assets/product/7/11.png" alt="">
            <span class="s-title">数据安全保驾护航</span>
            <span class="s-desc c666">对用户订单等数据处理保存采取符合数据安全原则， 经过安全测试审计。</span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {
      activeName: "first"
    };
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>

<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image" :style="{'backgroundImage':`url(${require('@/assets/product/4/1.png')})`}">
        <div class="wrapper">
          <el-row>
            <el-col :span="12">
              <div class="f50 cfff title">燃机进气选型系统</div>
              <div class="f20 cfff desc">该选型软件基于大数据、人工智能和云计算技术，将传统进气系统设计技术与最新的智能化技术进行深度融合，实现燃机进气系统的智能化、科学化、高效化设计选型。
              </div>
            </el-col>
            <el-col :span="12">
              <img class="r-img ranji" src="@/assets/product/4/2.png" alt="">
            </el-col>
          </el-row>

        </div>
      </div>
      <!-- <img class="product-image" src="@/assets/product/1/1.png" alt=""> -->
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/4/3.png" alt="">
          <div class="f16 c333 title">
            从企业发展的需要出发，致力于提高燃气轮机进气过滤系统自主化能力，实现燃气轮机技术的全面自主化和本地化。依赖人工智能与大数据的分析，在不同环境和不同燃机机型的情况下选择最适合的过滤系统，与之前的人工选型相比，智能选型方式更科学，而且效率高成本低。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :offset="3" :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/4/4.png" alt="">
              <p class="s-title">智能</p>
              <p class="s-desc">采用人工智能和云计算技术，在不同环境和不同燃机机型的情况下分析选出适合项目地的过滤系统。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/4/5.png" alt="">
              <p class="s-title">科学</p>
              <p class="s-desc">将进气系统设计技术与最新的技术进行深度融合，结合地区的AQI年平均指数、空气质量等呈现精准数据。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/4/6.png" alt="">
              <p class="s-title">高效</p>
              <p class="s-desc">依赖大数据分析技术进行设计选型，与之前的人工选型相比，智能选型方式效率更高。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/4/7.png')" :name="'多维度呈现'" :desc="'以图形化的方式多维度全方位展示项目地各个数据状态，根据AQI年平均指数、空气质量平均指数、项目地气候、燃机型号等数据协助应用系统快速定位和解决项目地选型设计。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/4/8.png')" :align="'right'" :name="'数据分析'" :desc="'通过数据分析能力快速获取项目地选型结果，依赖人工智能和云计算保证数据和选型的准确性，给用户推荐燃机机型首选方案和次选方案，用户可根据需要下载自己的选型结果。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/4/9.png')" :name="'报告输出'" :desc="'通过选型报告可获取选型系统基本结构，设备供货范围，进气系统规格，材料要求，油漆喷涂要求，检查及实验要求，选址附近污染源和距海岸线距离等信息。'">
      </platform-item>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>

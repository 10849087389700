<template>
  <div class="iriets-product">
    <div class="top-banner">
      <div class="product-image" :style="{'backgroundImage':`url(${require('@/assets/product/5/1.png')})`}">
        <div class="wrapper">
          <el-row :gutter="40">
            <el-col :span="10">
              <div class="f50 cfff title">数据展厅系统</div>
              <div class="f20 cfff desc">数据展厅系统整合对接Android广告机、Windows一体机、广州方创和西安诺瓦播控系统，实现对展厅、机房、办公室内的所有平台进行统一管理、统一发布。
              </div>
            </el-col>
            <el-col :span="14">
              <img class="r-img data-img" src="@/assets/product/5/2.png" alt="">
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <p class="title">平台概述</p>
    <div class="wrapper summary">
      <el-card shadow="always">
        <div class="flex">
          <img src="@/assets/product/5/3.png" alt="">
          <div class="f16 c333 title">
            系统采用一台电脑控制多台播放终端机，管理分布在网络上的各个播放终端。根据客户需求及方便，将各个展示模块设置为单独控制的子系统，集中控制展厅所有应用，也可对某个区域或功能进行单独控制。展厅中所有操作都可一键掌控，无需走到各设备前一一操作。
          </div>
        </div>
      </el-card>
    </div>
    <div class="advantages">
      <p class="title">平台优势</p>
      <div class="wrapper">
        <el-row :gutter="40">
          <el-col :offset="3" :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/5/4.png" alt="">
              <p class="s-title">多平台整合</p>
              <p class="s-desc">深度对接Android广告机、Windows一体机、广州方创和西安诺瓦播控系统，实现多平台统一管理、统一发布。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/5/5.png" alt="">
              <p class="s-title">多场景发布</p>
              <p class="s-desc">利用BS架构的轻松应对展厅、机房、办公室等多场景发布需求。</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always">
              <img src="@/assets/product/5/6.png" alt="">
              <p class="s-title">内容统一纳管</p>
              <p class="s-desc">统一发布系统提供内容管理模块，可对展示平台中的视频、图片、PPT、文字资源进行统一纳管。</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="platform">
      <p class="title">平台功能</p>
      <platform-item :productImg="require('@/assets/product/5/7.png')" :name="'平台整合'" :desc="'采用分布式设计，可以对终端分组呈现和管理，如播控展示系统、Android广告机、Windows一体机等。内容、播放计划可以统一集中控制。支持远程、本地或本机控制，可以群组控制也可以单点控制，管理方式多样化，让用户轻松管理大量播放终端。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/5/8.png')" :align="'right'" :name="'一对一建模'" :desc="'对展厅内的展示平台进行一对一建模、针对不同展示平台进行多样化展示，如矩阵屏、环形屏等。可对平台多个播放内容及版面灵活编排，版面与版面之间内容可随意切换，充分满足业务的需求，提高管理维护的工作效率。'">
      </platform-item>
      <platform-item :productImg="require('@/assets/product/5/9.png')" :name="'内容管理'" :desc="'内容管理页面包含视频素材、图片素材、PPT素材和文字素材4种类型的素材界面。支持视频、图片、PPT和文字内容新增、预览、删除等操作，能够有效提高内容发布速度和质量。'">
      </platform-item>
      <platform-item :bgColor="'#f2f6fc'" :productImg="require('@/assets/product/5/10.png')" :align="'right'" :name="'操作审计'" :desc="'能够对多个用户的登录记录、展示平台的管理操作记录和内容管理操作记录等进行实时详细的记录。支持操作内容、系统模块和状态等多条件查询。'">
      </platform-item>
    </div>
  </div>
</template>

<script>
import platformItem from "@/components/platformItem.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    platformItem
  }
};
</script>

<style scoped lang="less">
</style>
